import React from "react";
import { VIATIC_PERMISSION } from "../constants/permission";
import {
  getEmpty,
  getModelByIdAuth,
  getModelByViatico,
  getModelByViaticoPendiente,
} from "../constants/request";
import firebase from "../services/firebase";
import useGet from "./useGet";
import Notifier from "react-desktop-notification";
import bgImage from "./../utils/images/lo.png";
import Services from "./../services/servicesFirebase";

const NotificacionContext = React.createContext();

export function NotificacionProvider(props) {
  const [contNotificacion, setContNotificacion] = React.useState(1);

  const [userSession, setUserSession] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [userRequest, setUserRequest] = React.useState(getEmpty);

  const [requestPendientes, setRequestPendientes] = React.useState(getEmpty);
  const [dataPendientes] = useGet(requestPendientes);

  const [contData, setContData] = React.useState(0);
  const [fechaNuevaSoli, setFechaNuevaSoli] = React.useState("");

  const [cicloConsultaV, setCicloConsultaV] = React.useState(0);
  const [lista, setLista] = React.useState([]);

  const [userAuth] = useGet(userRequest);

  // const [data1, data2, data3, contData, fechaNuevaSoli] = useApp();

  React.useEffect(() => {
    setContNotificacion(contData);
  }, [contData]);

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(function onAuthStateChanged(user) {
      if (user) {
        const req = getModelByIdAuth(
          "auth",
          user.uid,
          "permiso,distritosAuthDf,permisoExtra"
        );
        setUserRequest(req);
      } else {
        setUserRequest(getEmpty);
      }
      setUserSession(user);
      setIsLoading(false);
      setRequestPendientes(getModelByViaticoPendiente("viatico"));
      setContData(0);
    });
  }, []);

  React.useEffect(() => {
    let viaticos =
      userAuth &&
      userAuth[0] &&
      userAuth[0].permiso &&
      userAuth[0].permiso.viaticos;

    if (
      (viaticos & VIATIC_PERMISSION.AUTHORIZED) ===
        VIATIC_PERMISSION.AUTHORIZED ||
      (viaticos & VIATIC_PERMISSION.DENIED) === VIATIC_PERMISSION.DENIED ||
      (viaticos & VIATIC_PERMISSION.AUDIT) === VIATIC_PERMISSION.AUDIT
    ) {
      if (userAuth) {
        firebase
          .firestore()
          .collection("viaticos")
          .where("uid", "==", userAuth[0].uid)
          .limit(1)
          .onSnapshot(async (docs) => {
            if (!docs.docs[0]) {
              setContData(parseInt(dataPendientes.total));
              await Services.addDocument("viaticos", {
                uid: userAuth[0].uid,
                notificacionCont: parseInt(dataPendientes.total),
                fecha: dataPendientes.fecha,
              });
            } else {
              setContData(docs.docs[0].data().notificacionCont);
              setFechaNuevaSoli(docs.docs[0].data().fecha);
              if (
                docs.docs[0].data().notificacionCont > 0 &&
                fechaNuevaSoli != docs.docs[0].data().fecha
              ) {
                let soli = "";
                if (docs.docs[0].data().notificacionCont > 1) {
                  soli = " Solicitudes pendientes.";
                } else {
                  soli = " Solicitud pendiente.";
                }
                Notifier.start(
                  "Defensa Sonora 2021",
                  "Usted tiene " + docs.docs[0].data().notificacionCont + soli,
                  "https://defensa.sireson.com/",
                  bgImage
                );
              }
            }
          });
      }
    }
  }, [userAuth]);

  const memData = React.useMemo(() => {
    return [contNotificacion];
  }, [contNotificacion]);

  return <NotificacionContext.Provider value={memData} {...props} />;
}

export function useNotificacion() {
  const context = React.useContext(NotificacionContext);
  if (!context) {
    throw "error: notificacion context not defined.";
  }
  return context;
}
