import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useHistory } from "react-router-dom";
import FullScreenLoader from "../components/FullScreenLoader";
import { useApp } from "../hooks/useApp";
import { usePermissions } from "../hooks/usePermissions";
import PERMISSION from "../constants/permission";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [userSession, isLoading] = useApp();

  const RenderBody = (props) => {
    const { checkPermission } = usePermissions();
    const history = useHistory();

    if (userSession) {
      // console.log("checking permission read");
      const res = checkPermission(PERMISSION.READ);
      //console.log(res);
      if (res || history.location.pathname === "/")
        return <Component {...props} />;
      else
        return (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      // history.push("/");
    }
    // console.log("redirect to signin");
    return (
      <Redirect to={{ pathname: "/signin", state: { from: props.location } }} />
    );
  };

  if (isLoading) return <FullScreenLoader />;
  return <Route {...rest} render={(props) => <RenderBody {...props} />} />;
};

PrivateRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func,
};

export default PrivateRoute;
