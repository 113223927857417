import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import ListIcon from "@material-ui/icons/List";
import { usePermissions } from "../hooks/usePermissions";
import PERMISSION from "../constants/permission";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { useNotificacion } from "./../hooks/useNotificacion";
import Badge from "@material-ui/core/Badge";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import DepartureBoardIcon from "@material-ui/icons/DepartureBoard";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GroupIcon from "@material-ui/icons/Group";
import AppsIcon from "@material-ui/icons/Apps";
import CategoryIcon from "@material-ui/icons/Category";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import MapIcon from "@material-ui/icons/Map";
import ArchiveIcon from "@material-ui/icons/Archive";
import BallotIcon from "@material-ui/icons/Ballot";
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import SmsIcon from '@material-ui/icons/Sms';
import TelegramIcon from '@material-ui/icons/Telegram';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import ListAltIcon from '@material-ui/icons/ListAlt';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ListItemsView = () => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const [administracion, setAdministracion] = React.useState(false);
  const [diaD, setDiaD] = React.useState(false);
  const [capacitacion, setCapacitacion] = React.useState(false);
  const [defensa, setDefensa] = React.useState(false);
  const [importaciones, setImportaciones] = React.useState(false);
  const [enlaces, setEnlaces] = React.useState(false);
  const [reportes, setReportes] = React.useState(false);
  const [sms, setSms] = React.useState(false);
  const [data, setData] = useNotificacion();


  const administracionClick = () => {
    setAdministracion(!administracion);
  };

  const SmsClick = () => {
    setSms(!sms);
  };

  const enlacesClick = () => {
    setEnlaces(!enlaces);
  };

  const defensaClick = () => {
    setDefensa(!defensa);
  };

  const importacionesClick = () => {
    setImportaciones(!importaciones);
  };

  const capacitacionClick = () => {
    setCapacitacion(!capacitacion);
  };

  const diaDClick = () => {
    setDiaD(!diaD);
  };

  const reportesClick = () => {
    setReportes(!reportes);
  };

  if (!permissions) return null;

  let admin =
    (permissions.estados & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.grupos & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.municipios & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.tipoincidencias & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.sectores & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.usuarios & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.capacitacion & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.importaciones & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.nominas & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.viaticos & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.sms & PERMISSION.READ) === PERMISSION.READ;

  let CR =
    (permissions.representantes & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.casillas & PERMISSION.READ) === PERMISSION.READ;

  let Diad =
    (permissions.incidencias & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.actas & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.supervisiones & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.fisicas & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.flujos & PERMISSION.READ) === PERMISSION.READ ||
    (permissions.votacion & PERMISSION.READ) === PERMISSION.READ;

  return (
    <>
      {permissions.rol === 'CGA' && (
         <List
         component="nav"
         aria-labelledby="nested-list-subheader"
         className={classes.root}
       >
          <>      
            <ListItem
              button
              onClick={enlacesClick}
              //style={{ left: 10 }}
            >
              <ListItemIcon>
                <MoveToInboxIcon />
              </ListItemIcon>
              <ListItemText primary="DIA D" />
              {enlaces ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={enlaces} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  <NavLink
                    to="/dia-d/votacion"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ListAltIcon />
                      </ListItemIcon>
                      <ListItemText primary="Votacion" />
                    </ListItem>
                  </NavLink>
              </List>
              <List component="div" disablePadding>
                  <NavLink
                    to="/dia-d/casillas"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ListAltIcon />
                      </ListItemIcon>
                      <ListItemText primary="Centro de Control" />
                    </ListItem>
                  </NavLink>
              </List>
              <List component="div" disablePadding>
                <NavLink
                  to="/dia-d/monitoreo"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Monitoreo" />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>
          </>
       </List>
      )}
      {permissions.rol === 'CA' && (
       <List
         component="nav"
         aria-labelledby="nested-list-subheader"
         className={classes.root}
       >
          <>      
            <ListItem
              button
              onClick={enlacesClick}
              //style={{ left: 10 }}
            >
              <ListItemIcon>
                <MoveToInboxIcon />
              </ListItemIcon>
              <ListItemText primary="CENTRO DE A." />
              {enlaces ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={enlaces} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  <NavLink
                    to="/fisicas"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ListAltIcon />
                      </ListItemIcon>
                      <ListItemText primary="FISICO" />
                    </ListItem>
                  </NavLink>
              </List>
            </Collapse>
          </>
       </List>
      )}
      {(permissions.rol !== 'CGA' && permissions.rol !== 'CA')  && (
        <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        {/* ENLACES */}
        {
              <>
                {(permissions.validaciones & PERMISSION.READ) ===
                  PERMISSION.READ && (
                <>
                <ListItem
                  button
                  onClick={enlacesClick}
                  //style={{ left: 10 }}
                >
                  <ListItemIcon>
                    <HeadsetMicIcon />
                  </ListItemIcon>
                  <ListItemText primary="Enlace Distrital" />
                  {enlaces ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={enlaces} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {(permissions.validaciones & PERMISSION.READ) ===
                      PERMISSION.READ && (
                      <NavLink
                        to="/validaciones"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <DoneOutlineIcon />
                          </ListItemIcon>
                          <ListItemText primary="Validacion" />
                        </ListItem>
                      </NavLink>
                    )}
                  </List>
                  <List component="div" disablePadding>
                    <NavLink
                      to="/dia-d/monitoreo"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <ListIcon />
                        </ListItemIcon>
                        <ListItemText primary="Monitoreo" />
                      </ListItem>
                    </NavLink>
                 </List>
                </Collapse>
                </>
                )}
              </>
            }


        {/* Administracion */}
        {admin && (
          <>
            <ListItem button onClick={administracionClick}>
              <ListItemIcon>
                <InsertChartIcon />
              </ListItemIcon>
              <ListItemText
                primary="Administración"
                style={{ whiteSpace: "pre-wrap" }}
              />
              {administracion ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={administracion} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {(permissions.estados & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/estados"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <MapIcon />
                      </ListItemIcon>
                      <ListItemText primary="Estados" />
                    </ListItem>
                  </NavLink>
                )}
                {(permissions.grupos & PERMISSION.READ) === PERMISSION.READ && (
                  <NavLink
                    to="/grupos"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <GroupWorkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Grupos" />
                    </ListItem>
                  </NavLink>
                )}
                {(permissions.municipios & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/municipios"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <LocationOnIcon />
                      </ListItemIcon>
                      <ListItemText primary="Municipios" />
                    </ListItem>
                  </NavLink>
                )}
                {(permissions.tipoincidencias & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/tipoincidencias"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <CategoryIcon />
                      </ListItemIcon>
                      <ListItemText primary="Tipo Incidencias" />
                    </ListItem>
                  </NavLink>
                )}
                {(permissions.sectores & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/sectores"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <AppsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Sectores" />
                    </ListItem>
                  </NavLink>
                )}
                {(permissions.usuarios & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/usuarios"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary="Usuarios" />
                    </ListItem>
                  </NavLink>
                )}
                {(permissions.capacitacion & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/capacitacion"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <AssignmentIcon />
                      </ListItemIcon>
                      <ListItemText primary="Rubros Capacitación" />
                    </ListItem>
                  </NavLink>
                )}
                {/* {(permissions.importaciones & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/importaciones/casillas"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ImportExportIcon />
                      </ListItemIcon>
                      <ListItemText primary="Importaciones" />
                    </ListItem>
                  </NavLink>
                )} */}
                {/* IMPORTACIONES */}
                {
                  <>
                    <ListItem
                      button
                      onClick={importacionesClick}
                      style={{ left: 10 }}
                    >
                      <ListItemIcon>
                        <ImportExportIcon />
                      </ListItemIcon>
                      <ListItemText primary="Importaciones" />
                      {importaciones ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={importaciones} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {(permissions.importaciones & PERMISSION.READ) ===
                          PERMISSION.READ && (
                          <NavLink
                            to="/importaciones/casillas"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <ListItem button className={classes.nested}>
                              <ListItemIcon>
                                <ArchiveIcon />
                              </ListItemIcon>
                              <ListItemText primary="Casillas" />
                            </ListItem>
                          </NavLink>
                        )}
                        {
                          <NavLink
                            to="/importaciones/nominaListadoImportacion"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <ListItem button className={classes.nested}>
                              <ListItemIcon>
                                <BallotIcon />
                              </ListItemIcon>
                              <ListItemText primary="Nomina Listado" />
                            </ListItem>
                          </NavLink>
                        }
                      </List>
                    </Collapse>
                  </>
                }
                {(permissions.viaticos & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/viaticos"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <DepartureBoardIcon />
                      </ListItemIcon>
                      <ListItemText primary="Viáticos" />
                      <ListItemIcon>
                        <Badge badgeContent={data} color="secondary">
                          <NotificationsNoneIcon />
                        </Badge>
                      </ListItemIcon>
                    </ListItem>
                  </NavLink>
                )}
                {(permissions.nominas & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/nominas"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <AccountBalanceWalletIcon />
                      </ListItemIcon>
                      <ListItemText primary="Nómina" />
                    </ListItem>
                  </NavLink>
                )}
              </List>
            </Collapse>
          </>
        )}
        {/* Defensa */}
        {CR && (
          <>
            <ListItem button onClick={defensaClick}>
              <ListItemIcon>
                <CardTravelIcon />
              </ListItemIcon>
              <ListItemText
                primary="Defensa"
                style={{ whiteSpace: "pre-wrap" }}
              />
              {defensa ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={defensa} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {(permissions.representantes & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/representantes"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ListIcon />
                      </ListItemIcon>
                      <ListItemText primary="Representantes" />
                    </ListItem>
                  </NavLink>
                )}
                {(permissions.casillas & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/casillas"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ListIcon />
                      </ListItemIcon>
                      <ListItemText primary="Asignar Casillas" />
                    </ListItem>
                  </NavLink>
                )}
              </List>
            </Collapse>
          </>
        )}
        {/* Capacitacion */}
        {(permissions.eventos & PERMISSION.READ) === PERMISSION.READ && (
          <>
            <ListItem button onClick={capacitacionClick}>
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText
                primary="Capacitación"
                style={{ whiteSpace: "pre-wrap" }}
              />
              {capacitacion ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={capacitacion} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {(permissions.eventos & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/eventos"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ListIcon />
                      </ListItemIcon>
                      <ListItemText primary="Capacitación" />
                    </ListItem>
                  </NavLink>
                )}
              </List>
            </Collapse>
          </>
        )}
        {/* diaD */}
        {Diad && (
          <>
            <ListItem button onClick={diaDClick}>
              <ListItemIcon>
                <AllInboxIcon />
              </ListItemIcon>
              <ListItemText
                primary="Día D"
                style={{ whiteSpace: "pre-wrap" }}
              />
              {diaD ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={diaD} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {(permissions.incidencias & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/dia-d/casillas"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ListIcon />
                      </ListItemIcon>
                      <ListItemText primary="Centro de Control" />
                    </ListItem>
                  </NavLink>
                )}
              </List>
              <List component="div" disablePadding>
                {(permissions.votacion & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/dia-d/votacion"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ListIcon />
                      </ListItemIcon>
                      <ListItemText primary="Votación" />
                    </ListItem>
                  </NavLink>
                )}
              </List>
              <List component="div" disablePadding>
                <NavLink
                  to="/dia-d/monitoreo"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Monitoreo" />
                  </ListItem>
                </NavLink>
              </List>
              <List component="div" disablePadding>
               {(permissions.actas & PERMISSION.READ) ===
                  PERMISSION.READ && (
                <NavLink
                  to="/actas"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Centro de Acopio" />
                  </ListItem>
                </NavLink>
                )}
              </List>
              <List component="div" disablePadding>
               {(permissions.supervisiones & PERMISSION.READ) ===
                  PERMISSION.READ && (
                <NavLink
                  to="/supervisiones"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Supervicion" />
                  </ListItem>
                </NavLink>
                )}
              </List>
              <List component="div" disablePadding>
               {(permissions.fisicas & PERMISSION.READ) ===
                  PERMISSION.READ && (
                <NavLink
                  to="/fisicas"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Fisicas" />
                  </ListItem>
                </NavLink>
                )}
              </List>
              <List component="div" disablePadding>
               {(permissions.flujos & PERMISSION.READ) ===
                  PERMISSION.READ && (
                <NavLink
                  to="/flujos"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Flujo Convecidos" />
                  </ListItem>
                </NavLink>
                )}
              </List>
            </Collapse>
          </>
        )}
        {/* Reportes */}
        {(permissions.reportes & PERMISSION.READ) === PERMISSION.READ && (
          <>
            <ListItem button onClick={reportesClick}>
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText
                primary="Reportes"
                style={{ whiteSpace: "pre-wrap" }}
              />
              {reportes ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={reportes} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {(permissions.reportes & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/reportes"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <ListIcon />
                      </ListItemIcon>
                      <ListItemText primary="Generales" />
                    </ListItem>
                  </NavLink>
                )}
              </List>
            </Collapse>
          </>
        )}
        {/* Sms */}
        {(permissions.sms & PERMISSION.READ) === PERMISSION.READ && (
          <>
            <ListItem button onClick={SmsClick}>
              <ListItemIcon>
                <TelegramIcon />
              </ListItemIcon>
              <ListItemText
                primary="Mensajeria"
                style={{ whiteSpace: "pre-wrap" }}
              />
              {sms ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={sms} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {(permissions.sms & PERMISSION.READ) ===
                  PERMISSION.READ && (
                  <NavLink
                    to="/sms"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <SmsIcon />
                      </ListItemIcon>
                      <ListItemText primary="SMS" />
                    </ListItem>
                  </NavLink>
                )}
              </List>
            </Collapse>
          </>
        )}
      </List>
      )}
      
    </>
  );
};

export default ListItemsView;
